import axios from 'axios'
import store from '../../store/index'

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API_URL
});

const token = localStorage.getItem('accessToken');
instance.defaults.headers.common['Authorization'] = 'JWT ' + token;

export default instance

